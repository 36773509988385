body {
  padding-top: 85px; }

.mobile-top-padding {
  display: block;
  height: 13px; }

.double-spacing {
  line-height: 2.6rem; }

.accent {
  /*font-family: 'Fjalla One', serif;*/ }

.nav-text {
  font-size: 1.4rem; }

textarea {
  resize: none; }

.text-standard {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.text-dark {
  color: #151046; }

.text-default {
  color: #fefefe; }

.text-alt {
  color: #fefefe; }

.bg-default {
  background-color: #171A2B; }

.text-primary {
  color: #f28130; }

.txt-primary {
  color: #f28130; }

.bg-primary {
  background-color: #f28130; }

.text-secondary {
  color: #8ec34a; }

a {
  color: #8ec34a; }

a:hover {
  color: #fefefe;
  text-decoration: none; }

.bg-secondary {
  background-color: #8ec34a; }

.bg-alt {
  background-color: #fefefe; }

.text-main {
  color: #fefefe; }

.bg-main {
  background-color: #fefefe; }

.italic {
  font-style: italic; }

.text-spacing {
  letter-spacing: .3rem; }

.text-spacing-2 {
  letter-spacing: .6rem; }

.uppercase {
  text-transform: uppercase; }

.smaller {
  font-size: 1rem; }

.pnl {
  margin-bottom: 2px;
  padding: 1rem; }

.peachtree-slider-img {
  border: 12px #fff solid; }

.col-xs-6 .peachtree-slider-img {
  border: 10px #fff solid; }

.block {
  display: block; }

.text-huge {
  font-size: 4em; }

.text-bigger {
  font-size: 2.4rem; }

.cinema {
  padding-top: -1rem;
  padding-bottom: 1rem; }

.cinema-2x {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.rounded {
  border-radius: 1rem; }

.bg-subtle {
  background: #2e3352; }

.panel-pad {
  padding: 2rem; }

.none {
  display: none; }

.link-hover-primary:hover {
  color: #f28130;
  background-color: transparent !important; }

.link-hover-secondary:hover {
  color: #8ec34a;
  background-color: transparent !important; }

.link-hover-default:hover {
  color: #fefefe;
  background-color: transparent !important; }

.text-minimal {
  color: #3F4461; }

.sweep-bg-primary:before {
  background: #f28130; }

.sweep-bg-default:before {
  background: #171A2B; }

.sweep-bg-secondary:before {
  background: #8ec34a; }

.sweep-bg-alt:before {
  background: #fefefe; }

.no-border {
  border: 0; }

.caption-bg {
  background: rgba(0, 0, 0, 0.2);
  padding: 1rem; }

.padding-nil {
  padding: 0; }

.content-spacer {
  margin: 2rem 0; }

.slider-options {
  bottom: 0; }

.restrict-height {
  max-height: 400px;
  overflow: hidden; }

.full-width {
  min-width: 100%; }

.faded {
  opacity: 0.4; }

.text-subtle {
  color: #8CA4B3; }

ul, ol {
  -webkit-padding-start: 24px; }

.divider {
  border-top-width: 0;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.opacity-hover {
  opacity: 0.8; }

.opacity-hover:hover {
  opacity: 1; }

.item .thumb {
  width: 25%;
  cursor: pointer;
  float: left;
  margin-right: 1.25%; }

.item .thumb:first-child {
  margin-left: 10%; }

.item .thumb img {
  width: 100%;
  margin: 6px; }

.item img {
  width: 100%; }

@media (max-width: 991px) {
  .navbar-header {
    float: none; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .navbar-text {
    float: none;
    margin: 15px 0; }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important; }
  .collapsing {
    overflow: hidden !important; } }

.max-height {
  margin-top: -10px;
  max-height: 70px; }

.navbar-fixed-top {
  min-height: 80px; }

.navbar-nav > li > a,
.navbar-header span {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 80px; }

@media (max-width: 991px) {
  .navbar-nav > li > a,
  .navbar-header span {
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px; } }

.google-maps {
  position: relative;
  padding-bottom: 400px;
  /* This is the aspect ratio */
  height: 0;
  overflow: hidden; }

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.google-maps iframe {
  pointer-events: none; }

@media (max-width: 1032px) {
  .brand-logo {
    margin-top: 0;
    max-width: 200px; } }

@media (max-width: 767px) {
  .no-padding-mobile {
    padding: 0; } }

/*# sourceMappingURL=app.css.map */
